var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PageLayout',{attrs:{"title":_vm.$t('withdrawal.title')}},[(_vm.loading)?_c('div',{staticClass:"loader-wrapper"},[_c('loader')],1):_vm._e(),(!_vm.loading)?_c('div',[_c('div',{staticClass:"cashier-box"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"small-width"},[(_vm.wallet !== null)?_c('div',[_c('WithdrawalStats',{attrs:{"currency":_vm.wallet.currency,"max-amount":_vm.wallet.balance,"bonus":_vm.wallet.credit}})],1):_vm._e(),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.amount),expression:"form.amount"}],staticClass:"cashier-form-control",attrs:{"type":"text","placeholder":[
								[
									_vm.$t('withdrawal.max-value', {
										value: _vm.formatCurrency(_vm.$i18n.locale, _vm.form.currency, _vm.form.maxAmount),
									}),
								],
							]},domProps:{"value":(_vm.form.amount)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "amount", $event.target.value)}}})]),_c('FormError',{attrs:{"error":_vm.formErrors.amount}}),_c('button',{staticClass:"button primary-button button-margin",attrs:{"type":"submit"}},[(_vm.withdrawalLoading)?_c('spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('withdrawal.withdraw'))+" ")],1),_c('p',{staticClass:"fineprint"},[_vm._v("* "+_vm._s(_vm.$t('withdrawal.bonus')))]),_c('pending-withdrawal')],1)])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }